var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
import AsyncStatus from "../../utils/async";
import updateShippingAddress from "../../api/mutations/updateShippingAddress";
import AddressFieldSet from "./AddressFieldSet.vue";
import { copyAddressWithoutGraphQlFields, createEmptyAddress, } from "../../data";
import NotificationsMixin from "../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
import { rethrowPossibleApolloErrorAsUserMessage } from "../../api/apolloErrorToUserMessage";
export default mixins(NotificationsMixin).extend({
    components: {
        AddressFieldSet: AddressFieldSet,
    },
    props: {
        viewerStudent: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        viewerStudent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment UpdateShippingForm_viewerStudent on Student {\n        id\n        shippingAddress {\n          streetAddress1\n          streetAddress2\n          city\n          postcode\n          state\n          country\n        }\n      }\n    "], ["\n      fragment UpdateShippingForm_viewerStudent on Student {\n        id\n        shippingAddress {\n          streetAddress1\n          streetAddress2\n          city\n          postcode\n          state\n          country\n        }\n      }\n    "]))),
    },
    data: function () {
        return {
            status: new AsyncStatus(),
            // Need to do this way so exclude __typename (or whatever other graphql or reactive stuff in obj)
            form: this.viewerStudent.shippingAddress
                ? copyAddressWithoutGraphQlFields(this.viewerStudent.shippingAddress)
                : createEmptyAddress(),
        };
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.$validator.validateAll().then(function (valid) {
                if (!valid) {
                    return;
                }
                _this.status
                    .run(_this.$apollo
                    .mutate(updateShippingAddress(_this.viewerStudent.id, _this.form))
                    .catch(rethrowPossibleApolloErrorAsUserMessage))
                    .then(function () {
                    return _this.displaySuccessMessage(_this.$t("Shipping address updated"));
                });
            });
        },
    },
});
var templateObject_1;
