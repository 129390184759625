var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
export default (function (input) { return ({
    mutation: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation updateBillingInformationMutation(\n      $input: UpdateBillingInformationInput!\n    ) {\n      updateBillingInformation(input: $input) {\n        viewerStudent {\n          id\n          billingAddress {\n            streetAddress1\n            streetAddress2\n            city\n            postcode\n            state\n            country\n            countryName\n          }\n          stripePaymentSource {\n            id\n            last4\n            expMonth\n            expYear\n          }\n        }\n      }\n    }\n  "], ["\n    mutation updateBillingInformationMutation(\n      $input: UpdateBillingInformationInput!\n    ) {\n      updateBillingInformation(input: $input) {\n        viewerStudent {\n          id\n          billingAddress {\n            streetAddress1\n            streetAddress2\n            city\n            postcode\n            state\n            country\n            countryName\n          }\n          stripePaymentSource {\n            id\n            last4\n            expMonth\n            expYear\n          }\n        }\n      }\n    }\n  "]))),
    variables: { input: input },
    // Optimistic response is impossible I think with stripeCustomer that has id created on back
}); });
var templateObject_1;
