var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('form-error-message',{attrs:{"status":_vm.status}}),_vm._v(" "),_c('div',{staticClass:"form-group flex flex-sb"},[_c('form-item-input',{directives:[{name:"validate",rawName:"v-validate",value:({
        required: !!_vm.viewerStudent.mobileNumber,
        englishOnly: true,
      }),expression:"{\n        required: !!viewerStudent.mobileNumber,\n        englishOnly: true,\n      }"}],staticClass:"w-50",attrs:{"name":"mobileNumber","label":_vm.$t('Mobile number'),"status":_vm.status},model:{value:(_vm.form.mobileNumber),callback:function ($$v) {_vm.$set(_vm.form, "mobileNumber", $$v)},expression:"form.mobileNumber"}}),_vm._v(" "),_c('form-item-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: !!_vm.viewerStudent.weChatId, englishOnly: true }),expression:"{ required: !!viewerStudent.weChatId, englishOnly: true }"}],staticClass:"w-50",attrs:{"name":"weChatId","label":_vm.$t('WeChat ID'),"status":_vm.status},model:{value:(_vm.form.weChatId),callback:function ($$v) {_vm.$set(_vm.form, "weChatId", $$v)},expression:"form.weChatId"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group flex flex-sb"},[_c('form-item-select',{directives:[{name:"validate",rawName:"v-validate",value:({ required: !!_vm.viewerStudent.passportCountry }),expression:"{ required: !!viewerStudent.passportCountry }"}],staticClass:"w-50",attrs:{"name":"passportCountry","label":_vm.$t('Passport country'),"options":_vm.countryOptions,"status":_vm.status},model:{value:(_vm.form.passportCountry),callback:function ($$v) {_vm.$set(_vm.form, "passportCountry", $$v)},expression:"form.passportCountry"}}),_vm._v(" "),_c('form-item-input',{directives:[{name:"validate",rawName:"v-validate",value:({
        required: !!_vm.viewerStudent.passportNumber,
        englishOnly: true,
      }),expression:"{\n        required: !!viewerStudent.passportNumber,\n        englishOnly: true,\n      }"}],staticClass:"w-50",attrs:{"name":"passportNumber","label":_vm.$t('Passport number'),"status":_vm.status},model:{value:(_vm.form.passportNumber),callback:function ($$v) {_vm.$set(_vm.form, "passportNumber", $$v)},expression:"form.passportNumber"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group flex flex-sb"},[_c('form-item-date',{directives:[{name:"validate",rawName:"v-validate",value:({
        required: !!_vm.viewerStudent.dateOfBirth,
        dateOfBirth: true,
      }),expression:"{\n        required: !!viewerStudent.dateOfBirth,\n        dateOfBirth: true,\n      }"}],staticClass:"w-50",attrs:{"name":"dateOfBirth","open-mode":"date-of-birth","label":_vm.$t('Date of birth'),"status":_vm.status},model:{value:(_vm.form.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.form, "dateOfBirth", $$v)},expression:"form.dateOfBirth"}}),_vm._v(" "),_c('form-item-select',{directives:[{name:"validate",rawName:"v-validate",value:({ required: !!_vm.viewerStudent.gender }),expression:"{ required: !!viewerStudent.gender }"}],staticClass:"w-50",attrs:{"name":"gender","label":_vm.$t('Gender'),"options":_vm.genderOptions,"status":_vm.status},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group flex flex-sb"},[_c('form-item-input',{directives:[{name:"validate",rawName:"v-validate",value:({
        required: !!_vm.viewerStudent.educationInstitute,
        englishOnly: true,
      }),expression:"{\n        required: !!viewerStudent.educationInstitute,\n        englishOnly: true,\n      }"}],staticClass:"w-50",attrs:{"name":"educationInstitute","label":_vm.$t('Education institute'),"status":_vm.status},model:{value:(_vm.form.educationInstitute),callback:function ($$v) {_vm.$set(_vm.form, "educationInstitute", $$v)},expression:"form.educationInstitute"}})],1),_vm._v(" "),_c('div',{staticClass:"form-item w-30"},[_c('form-submit-button',{attrs:{"status":_vm.status,"label":_vm.$t('Update'),"working-label":_vm.$t('Updating...')}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }