var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import gql from "graphql-tag";
import updateBillingInformation from "../../api/mutations/updateBillingInformation";
import BillingDetailsForm from "../ui/payment/BillingDetailsForm.vue";
import NotificationsMixin from "../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
import AsyncStatus from "../../utils/async";
export default mixins(NotificationsMixin).extend({
    components: {
        BillingDetailsForm: BillingDetailsForm,
    },
    props: {
        viewerStudent: {
            type: Object,
            default: undefined,
        },
    },
    data: function () {
        return { status: new AsyncStatus() };
    },
    fragments: {
        viewerStudent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment UpdateBillingForm_viewerStudent on Student {\n        id\n        fullName\n        shippingAddress {\n          streetAddress1\n          streetAddress2\n          city\n          postcode\n          state\n          country\n        }\n        ...BillingDetailsForm_viewerStudent\n      }\n      ", "\n    "], ["\n      fragment UpdateBillingForm_viewerStudent on Student {\n        id\n        fullName\n        shippingAddress {\n          streetAddress1\n          streetAddress2\n          city\n          postcode\n          state\n          country\n        }\n        ...BillingDetailsForm_viewerStudent\n      }\n      ", "\n    "])), BillingDetailsForm.options.fragments.viewerStudent),
    },
    methods: {
        onSubmit: function (details) {
            var _this = this;
            var cardUpdate = details.type === "new-card" ? { newCardToken: details.token } : {};
            var addressUpdate = details.billingAddress === "same-as-shipping"
                ? {}
                : { billingAddress: details.billingAddress };
            return this.$apollo
                .mutate(updateBillingInformation(__assign(__assign({}, cardUpdate), addressUpdate)))
                .then(function () {
                return _this.displaySuccessMessage(_this.$t("Billing information updated"));
            });
        },
    },
});
var templateObject_1;
