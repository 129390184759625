var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import gql from "graphql-tag";
import { SHIPPING_COUNTRIES_OPTIONS } from "../../data";
import find from "lodash/find";
export default (function (studentId, input) {
    var country = find(SHIPPING_COUNTRIES_OPTIONS, function (_a) {
        var value = _a.value;
        return value === input.country;
    });
    return {
        mutation: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      mutation updateShippingAddressMutation(\n        $input: UpdateShippingAddressInput!\n      ) {\n        updateShippingAddress(input: $input) {\n          viewerStudent {\n            id\n            shippingAddress {\n              streetAddress1\n              streetAddress2\n              city\n              postcode\n              state\n              country\n              countryName\n            }\n          }\n        }\n      }\n    "], ["\n      mutation updateShippingAddressMutation(\n        $input: UpdateShippingAddressInput!\n      ) {\n        updateShippingAddress(input: $input) {\n          viewerStudent {\n            id\n            shippingAddress {\n              streetAddress1\n              streetAddress2\n              city\n              postcode\n              state\n              country\n              countryName\n            }\n          }\n        }\n      }\n    "]))),
        variables: { input: input },
        optimisticResponse: {
            updateShippingAddress: {
                __typename: "UpdateShippingAddressOutput",
                viewerStudent: {
                    __typename: "Student",
                    id: studentId,
                    shippingAddress: __assign(__assign({ __typename: "Address" }, input), { countryName: country ? country.label : "" }),
                },
            },
        },
    };
});
var templateObject_1;
