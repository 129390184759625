var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import gql from "graphql-tag";
import { toApiDate } from "../transform";
export default (function (studentId, _a) {
    var dateOfBirth = _a.dateOfBirth, input = __rest(_a, ["dateOfBirth"]);
    var backendPersonal = __assign(__assign({}, input), { dateOfBirth: dateOfBirth ? toApiDate(dateOfBirth) : undefined });
    return {
        mutation: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      mutation updatePersonalInformationMutation(\n        $input: UpdatePersonalInformationInput!\n      ) {\n        updatePersonalInformation(input: $input) {\n          viewerStudent {\n            id\n            mobileNumber\n            weChatId\n            passportCountry\n            passportNumber\n            dateOfBirth\n            gender\n            educationInstitute\n          }\n        }\n      }\n    "], ["\n      mutation updatePersonalInformationMutation(\n        $input: UpdatePersonalInformationInput!\n      ) {\n        updatePersonalInformation(input: $input) {\n          viewerStudent {\n            id\n            mobileNumber\n            weChatId\n            passportCountry\n            passportNumber\n            dateOfBirth\n            gender\n            educationInstitute\n          }\n        }\n      }\n    "]))),
        variables: { input: backendPersonal },
        optimisticResponse: {
            updatePersonalInformation: {
                __typename: "UpdatePersonalInformationOutput",
                viewerStudent: __assign({ __typename: "Student", id: studentId }, backendPersonal),
            },
        },
    };
});
var templateObject_1;
