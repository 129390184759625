var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
import AsyncStatus from "../../utils/async";
import { allCountriesOptions, genderOptions } from "../../data";
import updatePersonalInformation from "../../api/mutations/updatePersonalInformation";
import { fromApiDate } from "../../api/transform";
import NotificationsMixin from "../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
import { rethrowPossibleApolloErrorAsUserMessage } from "../../api/apolloErrorToUserMessage";
export default mixins(NotificationsMixin).extend({
    props: {
        viewerStudent: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        viewerStudent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment UpdatePersonalForm_viewerStudent on Student {\n        id\n        mobileNumber\n        weChatId\n        passportCountry\n        passportNumber\n        dateOfBirth\n        gender\n        educationInstitute\n      }\n    "], ["\n      fragment UpdatePersonalForm_viewerStudent on Student {\n        id\n        mobileNumber\n        weChatId\n        passportCountry\n        passportNumber\n        dateOfBirth\n        gender\n        educationInstitute\n      }\n    "]))),
    },
    data: function () {
        return {
            countryOptions: allCountriesOptions,
            genderOptions: genderOptions,
            status: new AsyncStatus(),
            form: {
                mobileNumber: this.viewerStudent.mobileNumber || "",
                weChatId: this.viewerStudent.weChatId || "",
                passportCountry: this.viewerStudent.passportCountry || "",
                passportNumber: this.viewerStudent.passportNumber || "",
                dateOfBirth: fromApiDate(this.viewerStudent.dateOfBirth),
                gender: this.viewerStudent.gender || "",
                educationInstitute: this.viewerStudent.educationInstitute || "",
            },
        };
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.$validator.validate().then(function (valid) {
                if (!valid) {
                    return;
                }
                _this.status
                    .run(_this.$apollo
                    .mutate(updatePersonalInformation(_this.viewerStudent.id, _this.form))
                    .catch(rethrowPossibleApolloErrorAsUserMessage))
                    .then(function () {
                    return _this.displaySuccessMessage(_this.$t("Personal information updated"));
                });
            });
        },
    },
});
var templateObject_1;
