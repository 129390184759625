import AsyncStatus from "../../../utils/async";
import updateViewerPassword from "../../../api/mutations/updateViewerPassword";
import PasswordForm from "../PasswordForm.vue";
import NotificationsMixin from "../NotificationsMixin";
import mixins from "vue-typed-mixins";
export default mixins(NotificationsMixin).extend({
    components: {
        PasswordForm: PasswordForm,
    },
    data: function () {
        return {
            status: new AsyncStatus(),
        };
    },
    methods: {
        onProcessPassword: function (password) {
            var _this = this;
            return this.$apollo
                .mutate(updateViewerPassword(password))
                .then(function () { return _this.displaySuccessMessage(_this.$t("Password updated")); });
        },
    },
});
