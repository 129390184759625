var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import gql from "graphql-tag";
import AsyncStatus from "../../utils/async";
import NotificationsMixin from "../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
import { rethrowPossibleApolloErrorAsUserMessage } from "../../api/apolloErrorToUserMessage";
import updateEmailAddress from "../../api/mutations/updateEmailAddress";
import { mapMutations } from "vuex";
export default mixins(NotificationsMixin).extend({
    props: {
        viewerStudent: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        viewerStudent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment UpdateEmailAddressForm_viewerStudent on Student {\n        id\n        emailAddress\n      }\n    "], ["\n      fragment UpdateEmailAddressForm_viewerStudent on Student {\n        id\n        emailAddress\n      }\n    "]))),
    },
    data: function () {
        return {
            form: {
                emailAddress: this.viewerStudent.emailAddress,
            },
            status: new AsyncStatus(),
        };
    },
    methods: __assign({ onSubmit: function () {
            var _this = this;
            this.$validator.validate().then(function (valid) {
                if (!valid) {
                    return;
                }
                _this.status
                    .run(_this.$apollo
                    .mutate(updateEmailAddress(_this.form.emailAddress))
                    .catch(rethrowPossibleApolloErrorAsUserMessage))
                    .then(function (_a) {
                    var data = _a.data;
                    if (!data) {
                        throw new Error("Invalid login");
                    }
                    _this.updateAuthToken(data.updateEmailAddress.newToken);
                })
                    .then(function () {
                    return _this.displaySuccessMessage(_this.$t("Email address updated"));
                });
            });
        } }, mapMutations(["updateAuthToken"])),
});
var templateObject_1;
