var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
export default (function (studentId, input) { return ({
    mutation: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation updateNameMutation($input: UpdateNameInput!) {\n      updateName(input: $input) {\n        viewerStudent {\n          id\n          firstName\n          lastName\n          fullName\n        }\n      }\n    }\n  "], ["\n    mutation updateNameMutation($input: UpdateNameInput!) {\n      updateName(input: $input) {\n        viewerStudent {\n          id\n          firstName\n          lastName\n          fullName\n        }\n      }\n    }\n  "]))),
    variables: { input: input },
    optimisticResponse: {
        updateName: {
            __typename: "UpdateNameOutput",
            viewerStudent: {
                __typename: "Student",
                id: studentId,
                firstName: input.firstName,
                lastName: input.lastName,
                fullName: "".concat(input.firstName, " ").concat(input.lastName),
            },
        },
    },
}); });
var templateObject_1;
